<!--
File: Report_12_RoadLengthByElevationZones.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
            @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadClassesDropdown :label="$t('road_network.road_class')" v-model="selectedRoadClass"
            :initial_value=selectedRoadClass @input='reloadData' />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />

      <md-table class="paginated-table table-striped table-hover" :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 200px;" :md-label="$t('road_network.region')"
            md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedRoadClass" :md-label="$t('road_network.road_class')" md-sort-by="road_class">
            {{ item.road_class }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_1') + $t('label.kilometers')" md-sort-by="zone_1" md-numeric>
            {{ item.zone_1 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_2') + $t('label.kilometers')" md-sort-by="zone_2" md-numeric>
            {{ item.zone_2 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_3') + $t('label.kilometers')" md-sort-by="zone_3" md-numeric>
            {{ item.zone_3 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_4') + $t('label.kilometers')" md-sort-by="zone_4" md-numeric>
            {{ item.zone_4 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_5') + $t('label.kilometers')" md-sort-by="zone_5" md-numeric>
            {{ item.zone_5 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.zone_6') + $t('label.kilometers')" md-sort-by="zone_6" md-numeric>
            {{ item.zone_6 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.total') + $t('label.kilometers')" md-sort-by="total" md-numeric>
            {{ item.total | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <md-table v-if="col_span > 0">
        <md-table-row>
          <md-table-head colspan="col_span">{{ $t('label.total') }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_1 | numFormat(3) }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_2 | numFormat(3) }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_3 | numFormat(3) }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_4 | numFormat(3) }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_5 | numFormat(3) }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.zone_6 | numFormat(3) }}</md-table-head>
          <md-table-head md-numeric>{{ totalZones.total | numFormat(3) }}</md-table-head>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import RoadClassesDropdown from '../Dropdowns/RoadClassesDropdown.vue'
import { numFormat } from "@/store/refdata/format_helpers"
import { customSortMixin } from '@/mixins/customSortMixin'
import printReport from '@/mixins/printMixin'

export default {
  mixins: [customSortMixin, printReport],
  name: 'report-road-length-by-elevation-zones',
  data() {
    return {
      selectedRegion: null,
      selectedRoadClass: null,
      btnDisabled: true,

      showSpinner: false,
      currentSort: 'region_description',
      currentSortOrder: 'asc',
    }
  },
  components: {
    RegionsDropdown,
    RoadClassesDropdown
  },

  mounted() {
    this.$store.commit('SET_REPORT_12', [])
    this.reloadData()
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      this.$store.dispatch('REPORT_12_LENGTH_BY_ELEVATION_ZONES').then(() => {
        this.btnDisabled = false
        this.showSpinner = false
      })
    },

    print() {
      this.showSpinner = true
      let prtHtml = "<h4 style='text-align:center'>" + this.$t('route.report_road_length_in_elevation_zones') + '<br>'

      let titleFilters = ''
      let tableHeaders = '<tr>'
      if (this.selectedRegion) {
        titleFilters += this.$t('label.from_region', { name: this.tableData[0].region_description })
      } else {
        tableHeaders += `<th>${this.$t('road_network.region')}</th>`
      }
      if (this.selectedRoadClass) {
        if (titleFilters !== '') titleFilters += ', &nbsp;'
        titleFilters += this.$t('road_network.road_class') + ': ' + this.selectedRoadClass
      } else {
        tableHeaders += `<th>${this.$t('road_network.road_class')}</th>`
      }
      if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
      prtHtml += '</h4>'

      tableHeaders += `
          <th>${this.$t('label.zone_1') + this.$t('label.kilometers')}</th>
          <th>${this.$t('label.zone_2') + this.$t('label.kilometers')}</th>
          <th>${this.$t('label.zone_3') + this.$t('label.kilometers')}</th>
          <th>${this.$t('label.zone_4') + this.$t('label.kilometers')}</th>
          <th>${this.$t('label.zone_5') + this.$t('label.kilometers')}</th>
          <th>${this.$t('label.zone_6') + this.$t('label.kilometers')}</th>
          <th>${this.$t('label.total') + this.$t('label.kilometers')}</th>
        </tr>`

      let tableRows = ''
      this.tableData.forEach(item => {
        tableRows += '<tr>'
        if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
        if (!this.selectedRoadClass) tableRows += `<td>${item.road_class}</td>`
        tableRows += `
            <td class='numCell'>${numFormat(item.zone_1, 3)}</td>
            <td class='numCell'>${numFormat(item.zone_2, 3)}</td>
            <td class='numCell'>${numFormat(item.zone_3, 3)}</td>
            <td class='numCell'>${numFormat(item.zone_4, 3)}</td>
            <td class='numCell'>${numFormat(item.zone_5, 3)}</td>
            <td class='numCell'>${numFormat(item.zone_6, 3)}</td>
            <td class='numCell'>${numFormat(item.total, 3)}</td>
          </tr>`
      })

      if (this.col_span) {
        tableRows += `<tr><th colspan=${this.col_span}>${this.$t('label.total')}</th>
          <th class='numCell'>${numFormat(this.totalZones.zone_1, 3)}</th>
          <th class='numCell'>${numFormat(this.totalZones.zone_2, 3)}</th>
          <th class='numCell'>${numFormat(this.totalZones.zone_3, 3)}</th>
          <th class='numCell'>${numFormat(this.totalZones.zone_4, 3)}</th>
          <th class='numCell'>${numFormat(this.totalZones.zone_5, 3)}</th>
          <th class='numCell'>${numFormat(this.totalZones.zone_6, 3)}</th>
          <th class='numCell'>${numFormat(this.totalZones.total, 3)}</th>
        </tr>`
      }
      this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
    },
  },

  computed: {
    report() {
      return this.customSort(this.$store.state.Reports.report_12_data, 'road_class')
    },
    tableData() {
      let res = !this.selectedRegion ? this.report : this.report.filter((item) => {
        return item.region_id == this.selectedRegion
      })
      if (this.selectedRoadClass) res = res.filter((item) => {
        return item.road_class == this.selectedRoadClass
      })
      return res
    },
    totalZones() {
      const zones = ['zone_1', 'zone_2', 'zone_3', 'zone_4', 'zone_5', 'zone_6', 'total'];
      const totals = zones.reduce((acc, zone) => ({ ...acc, [zone]: 0 }), {});

      return this.tableData.reduce((accumulator, currentValue) => {
        zones.forEach(zone => { accumulator[zone] += currentValue[zone]; });
        return accumulator;
      }, totals);
    },
    col_span() {
      let res = 2
      if (this.selectedRegion) res--
      if (this.selectedRoadClass) res--
      return res
    }
  },
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 14px !important;
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 12;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>