export default {
  methods: {
    printReport(reportHeader, tableHeaders, tableRows) {
      const html = `<!DOCTYPE html>
<html>
  <head>
    <style>
      body { font-family: Arial, sans-serif; }
      table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 11px; }
      th, td { border: 1px solid black; padding: 5px; overflow: visible; white-space: normal; word-wrap: break-word; }
      .numCell { text-align: right; padding-right: 5px; }
    </style>
  </head>
  <body>${reportHeader}
    <table>
      <thead>${tableHeaders}</thead>
      <tbody>${tableRows}</tbody>
    </table>
  </body>
</html>`

      const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      PrintWin.document.write(html)
      PrintWin.document.close();
      PrintWin.focus();
      PrintWin.print();
      PrintWin.close();
    }
  }
}
